
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Form',
    },
    data: {
      type: Object,
    },
  },
  setup (props) {
    const form = ref<any>(props.data)
    const loading = ref(false)
    const options = ['Admin', 'Retailer']
    const defaultIds = ref<Array<string>>([
      '551834f1-1ddd-4059-be5e-e43ee3647fc4',
      'aec0b8c0-e3e8-4980-b21b-fee141f3cf3b'
    ])

    const setLoading = (payload: boolean) => {
      loading.value = payload
    }

    const canEditView = computed(() => {
      return !defaultIds.value.includes(form.value.id)
    })

    return {
      options,
      form,
      loading,
      setLoading,
      canEditView
    }
  },
})
